//signalR
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
} from "@microsoft/signalr";
//api
import { postCreateUpdateConnectionId } from "../api/chat";

export class SignalRService {
  private connection: HubConnection;

  constructor(private url: string) {
    this.connection = new HubConnectionBuilder()
      .withUrl(url)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect() // Enable automatic reconnection
      .build();

    this.connection.onclose(this.handleDisconnect);
  }

  // Connect to backend signalR server
  public startConnection = async (): Promise<void> => {
    if (this.connection.state === HubConnectionState.Disconnected) {
      try {
        await this.connection.start();
        console.log("SignalR Connected.");

        // setInterval(() => {
        //   this.connection.invoke("Ping").catch((err) => console.error(err));
        // }, 900000);
      } catch (error) {
        console.error("SignalR Connection Error: ", error);
        setTimeout(this.startConnection, 3000);
      }
    }
  };

  // Disconnect backend signalR server
  public stopConnection = async (): Promise<void> => {
    await this.connection.stop();
    console.log("SignalR Disconnected.");
  };

  private handleDisconnect = (): void => {
    console.log("SignalR Disconnected. Attempting to reconnect...");
    setTimeout(this.startConnection, 3000);
  };

  // Set Connection with current users id
  public startConnectionWithId = async (currentUserId: string) => {
    //await this.startConnection();
    try {
      if (this.connection.state === HubConnectionState.Connected) {
        await this.connection.invoke(
          "SetConnectionId",
          currentUserId,
          this.connection.connectionId
        );
        console.log("SignalR Connected with currentUserId.");
      } else {
        console.log("SignalR connection is not yet established.");
      }
    } catch (error) {
      console.error("SignalR Connection with currentUserId Error: ", error);
    }
  };

  // Receive MemberId, ConnectionId, IsExistsInDB
  public receiveConnectionIds = async () => {
    try {
      // return new Promise((resolve) => {
      //   this.connection.on("OnSetConnectionId", (response) => {
      //     resolve(response);
      //   });
      // });
      this.connection.on("OnSetConnectionId", (connectionIds) => {
        connectionIds && postCreateUpdateConnectionId(connectionIds);
        //console.log("Connection ID set:", connectionIds);
      });
    } catch (error) {
      //throw error;
      console.error("Set ConeectionId Error: ", error);
    }
  };

  // Reconnect to signalR server when connection is lost
  public startConnectionToServer = async (currentUserId: string) => {
    try {
      await this.receiveConnectionIds();

      await this.startConnection();

      await this.startConnectionWithId(currentUserId); //current user id
    } catch (error) {
      //throw error;
      console.error("SignalR Connection Error: ", error);
    }
  };

  // Detect if the connection is still active
  public isConnected = (): boolean => {
    return this.connection.state === HubConnectionState.Connected;
  };

  // Send Message
  public sendMessage = async (
    message: any,
    receiverId: string,
    isReceiverSupport: boolean = false
  ) => {
    try {
      await this.connection.invoke(
        "SendMessage",
        message,
        (message.ReceiverId = receiverId),
        isReceiverSupport
      );
      console.log("Message sent successfully");
    } catch (error) {
      console.error("Sending message Error: ", error);
    }
  };

  // Receive opponent's Message
  public receiveMessage = () => {
    try {
      return new Promise((resolve) => {
        this.connection.on("ReceiveMessage", (message) => {
          resolve(message);
        });
      });
    } catch (error) {
      throw error;
    }
  };

  // Receive message sent by current user
  public receiveSelfMessage = () => {
    try {
      return new Promise((resolve) => {
        this.connection.on("SelfReceiveMessage", (message) => {
          resolve(message);
        });
      });
    } catch (error) {
      throw error;
    }
  };

  // Tell SignalR message is read by receiver
  public messageIsSeen = async (
    messageId: string,
    isSeenForSupportMessage: boolean
  ) => {
    try {
      await this.connection.invoke(
        "MessageSeen",
        messageId,
        isSeenForSupportMessage
      );
      console.log("Inform Server that message is read successfully");
    } catch (error) {
      console.error("MessageSeen Error: ", error);
    }
  };

  // Mark message is read
  public markMessageAsIsRead = () => {
    try {
      return new Promise((resolve) => {
        this.connection.on("MarkMessageAsRead", (message) => {
          resolve(message);
        });
      });
    } catch (error) {
      throw error;
    }
  };

  // Inform sender of messages that all messages are read when receiver just enters the room
  public MessageSeenAsReceiverIn = () => {
    try {
      return new Promise((resolve) => {
        this.connection.on("MarkMessageAsReadWhenReceiverSee", (message) => {
          resolve(message);
        });
      });
    } catch (error) {
      throw error;
    }
  };

  // Updating chatroom list - invoke
  public UpdateChatroomListInvoke = async (userId: string) => {
    try {
      await this.connection.invoke("UpdatingChatroomList", userId);
      console.log("Updating chatroom list successfully");
    } catch (error) {
      console.error("Updating chatroom list Error: ", error);
    }
  };

  // Updating chatroom list - on
  public UpdateChatroomListOn = () => {
    try {
      return new Promise((resolve) => {
        this.connection.on("UpdateChatroomList", () => {
          resolve(true);
        });
      });
    } catch (error) {
      throw error;
    }
  };
}
