import axios from "axios";
import { baseUrl } from "./axiosInstance";

// api to get status of maintenance
export const getStatusOfMaintenance = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/websiteMaintenance/IsWebsiteOnMaintenance`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get Status of Maintenance Failed]: ", error);
  }
};
