//react
import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import { InfiniteData, useQueryClient } from "react-query";
//components
import SenderChatBubble from "../SenderChatBubble/SenderChatBubble";
import ChatBubble from "../ChatBubble/ChatBubble";
import ServiceQAMenu from "../ServiceQAMenu/ServiceQAMenu";
import ReplyControl from "../ReplyControl/ReplyControl";
//signalR
import { SignalRService } from "../../signalR/SignalRService";
//api
import { postMsgIsRead } from "../../api/chat";
import { postUpdateIsReadByMessageId } from "../../api/chat";
//context
import { useChat } from "../../context/ChatProvider";
import { useAuth } from "../../context/AuthContext";
//type
import { TypeMessage } from "../../types/chats";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./ServiceChatBox.scss";

interface ServiceChatBoxProps {
  connection: SignalRService | null;
  data: InfiniteData<TypeMessage[]> | undefined;
}

const ServiceChatBoxFunction: ForwardRefRenderFunction<
  HTMLDivElement,
  ServiceChatBoxProps
> = (props, ref) => {
  const [messages, setMessages] = useState<TypeMessage[]>([]);
  const [justEnter, setJustEnter] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [isHover, setIsHover] = useState(false);
  const [hasNewMsg, setHasNewMsg] = useState(false);
  let currentDate = "";
  let realTimeDate = "";

  // sroll down to the bottom of thhe chatroom
  const handleScrollToBottom = () => {
    if (ref && "current" in ref && ref.current) {
      const { scrollHeight, clientHeight } = ref.current;
      ref.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  };

  // hdie button of scrolling down
  const handleScroll = () => {
    if (ref && "current" in ref && ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      setIsAtBottom(scrollHeight - scrollTop === clientHeight);
      if (scrollHeight - scrollTop === clientHeight) {
        setIsHover(false);
        setHasNewMsg(false);
      }
    }
  };

  // context - memberId
  const { currentMember } = useAuth();

  //context
  const { selectedRoomId } = useChat();

  // mark message as read message
  const handleMsgIsRead = async () => {
    try {
      await postMsgIsRead({
        chatroomId: selectedRoomId,
        memberId: currentMember?.currentMemberId, // current user
      });
    } catch (error) {
      console.log(error);
    }
  };

  // process messages when first enter the room
  useEffect(() => {
    // make sure viewpoint is at latest messages when first enter the room
    // const firstUnread = props?.data?.pages[0]?.filter(
    //   (msg) =>
    //     msg?.isRead === false &&
    //     msg?.senderId !== currentMember?.currentMemberId // current uer
    // )?.[0]?.id;
    // const element = firstUnread && document.getElementById(firstUnread);
    // if (element) {
    //   element.scrollIntoView({ block: "start" });
    // }

    // viewpoint at the bottom when there has no unread massages
    // if (!firstUnread) {
    //   if (ref && "current" in ref && ref.current) {
    //     const { scrollHeight, clientHeight } = ref.current;
    //     ref.current.scrollTo({
    //       left: 0,
    //       top: scrollHeight - clientHeight,
    //       //behavior: "smooth",
    //     });
    //   }
    // }

    // fire api to make meesges in room read
    handleMsgIsRead();
  }, [justEnter]);

  // make sure process message after first enter the room and already get data of props?.data?.pages
  useEffect(() => {
    if (props?.data?.pages[0]) {
      setJustEnter(!justEnter);
    }
  }, [props?.data?.pages[0]]);

  ////////////// SignalR - code below /////////////////

  // use selectedRoomIdRef to make sure holding the latest selectedRoomId
  const selectedRoomIdRef = useRef(selectedRoomId);
  useEffect(() => {
    selectedRoomIdRef.current = selectedRoomId;
  }, [selectedRoomId]);

  // Mark single message sent by sender as read
  const getStatusOfRead = async () => {
    try {
      const msg =
        (await props?.connection?.markMessageAsIsRead()) as TypeMessage;

      if (msg) {
        //const res = await postUpdateIsReadByMessageId(msg?.id); // api to update status of read in db

        setMessages?.((msgs) => {
          return msgs?.map((message) => {
            if (message?.id === msg?.id) {
              return {
                ...message,
                isRead: true,
              };
            } else {
              return message;
            }
          });
        });
      }
    } catch (error) {
      console.error("[Error mark message as read]: ", error);
    }
  };

  getStatusOfRead();

  // Receive opponent's Message
  const getOpponentMessage = async () => {
    try {
      const newMessage =
        (await props?.connection?.receiveMessage()) as TypeMessage;

      if (newMessage) {
        if (newMessage?.chatroomId === selectedRoomIdRef.current) {
          setMessages([...messages, newMessage]);
          setHasNewMsg(true);

          const res = await postUpdateIsReadByMessageId(newMessage?.id);

          if (res) {
            // isRead implementation - this will trigger markMessageAsIsRead function
            props?.connection?.messageIsSeen(newMessage?.id, true);
          }
        }
      }
    } catch (error) {
      console.error("[Error receiving message]: ", error);
    }
  };

  getOpponentMessage();

  // Receive message sent by current user
  const getSelfMessage = async () => {
    try {
      const newMessage =
        (await props?.connection?.receiveSelfMessage()) as TypeMessage;

      if (newMessage) {
        newMessage?.chatroomId === selectedRoomIdRef.current &&
          setMessages([...messages, newMessage]);
      }
    } catch (error) {
      console.error("[Error receiving message]: ", error);
    }
  };

  getSelfMessage();

  // Know meassage has been read as receiver just joins the room
  const messageHasSeenByReceiver = async () => {
    try {
      const msg =
        (await props?.connection?.MessageSeenAsReceiverIn()) as TypeMessage;
      if (msg) {
        setMessages?.((msgs) => {
          return msgs?.map((message) => {
            if (message?.id === msg?.id) {
              return {
                ...message,
                isRead: true,
              };
            } else {
              return message;
            }
          });
        });
      }
    } catch (error) {
      console.error("[Error messages seen]: ", error);
    }
  };

  messageHasSeenByReceiver();

  // part to be deleted later on when connection issue solved - start
  const queryClient = useQueryClient();

  useEffect(() => {
    const refetch = () => {
      setMessages?.([]);
      queryClient.invalidateQueries("/messages");
    };

    // Set up an interval to call the function every 5 seconds
    const interval = setInterval(refetch, 30000);

    // Clean up the interval when the component is unmounted or dependencies change
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const reconnect = async () => {
      if (!props?.connection?.isConnected()) {
        console.log("connection is lost! reconnecting to signalR server...");

        // setFetchAgain?.(!fetchAgain);
        // queryClient.invalidateQueries("/messages");
        // setMessages?.([]);

        await props?.connection?.receiveConnectionIds();

        await props?.connection?.startConnection();

        await props?.connection?.startConnectionWithId(
          currentMember?.currentMemberId
        ); //current user id
      }
    };

    // Set up an interval to call the function every 5 seconds
    const interval = setInterval(reconnect, 5000);

    // Clean up the interval when the component is unmounted or dependencies change
    return () => clearInterval(interval);
  }, [props?.connection, currentMember?.currentMemberId]);
  // part to be deleted later on when connection issue solved - end

  return (
    <>
      <div className="service-box-container">
        <div className="chatbox-main-area" ref={ref} onScroll={handleScroll}>
          {hasNewMsg && (
            <div className="new-msg-tag r-14" onClick={handleScrollToBottom}>
              有新訊息
            </div>
          )}
          {props?.data?.pages
            ?.map((messages) =>
              messages?.map((msg) => {
                const msgDate = msg?.creationTime?.split("T")[0];

                // Turn string of files into array
                const msgFiles = msg?.fileContent?.split(",");

                if (currentDate !== msgDate) {
                  currentDate = msgDate;
                  return (
                    <div id={msg?.id} key={msg?.id}>
                      <div className="record-date r-12">
                        <span>{msgDate?.replaceAll("-", "/")}</span>
                      </div>
                      {msg?.senderId !== currentMember?.currentMemberId ? (
                        <SenderChatBubble
                          id={msg?.id}
                          msg={msg?.content}
                          msgFiles={msgFiles}
                          avatar={TemplateCardImg?.srcService_logo}
                          timestamp={msg?.creationTime
                            ?.split("T")[1]
                            .substring(0, 5)}
                          ownerId={msg?.senderId}
                        />
                      ) : (
                        <ChatBubble
                          id={msg?.id}
                          msg={msg?.content}
                          msgFiles={msgFiles}
                          timestamp={msg?.creationTime
                            ?.split("T")[1]
                            .substring(0, 5)}
                          isRead={msg?.isRead}
                          connection={props?.connection}
                        />
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div id={msg?.id} key={msg?.id}>
                      {msg?.senderId !== currentMember?.currentMemberId ? (
                        <SenderChatBubble
                          key={msg?.id}
                          id={msg?.id}
                          msg={msg?.content}
                          msgFiles={msgFiles}
                          avatar={TemplateCardImg?.srcService_logo}
                          timestamp={msg?.creationTime
                            ?.split("T")[1]
                            .substring(0, 5)}
                          ownerId={msg?.senderId}
                        />
                      ) : (
                        <ChatBubble
                          key={msg?.id}
                          id={msg?.id}
                          msg={msg?.content}
                          msgFiles={msgFiles}
                          timestamp={msg?.creationTime
                            ?.split("T")[1]
                            .substring(0, 5)}
                          isRead={msg?.isRead}
                          connection={props?.connection}
                        />
                      )}
                    </div>
                  );
                }
              })
            )
            .reverse()}
          {/* render real-time messages and Q&A responses */}
          {messages?.map((msg) => {
            const msgDate = msg?.creationTime?.split("T")[0];

            // Turn string of files into array
            const msgFiles = msg?.fileContent?.split(",");

            if (currentDate !== msgDate) {
              currentDate = msgDate;
              return (
                <div key={msg?.id}>
                  <div className="record-date r-12">
                    <span>{msgDate?.replaceAll("-", "/")}</span>
                  </div>
                  {msg?.senderId !== currentMember?.currentMemberId ? (
                    <SenderChatBubble
                      id={msg?.id}
                      msg={msg?.content}
                      msgFiles={msgFiles}
                      avatar={TemplateCardImg?.srcService_logo}
                      timestamp={msg?.creationTime
                        ?.split("T")[1]
                        .substring(0, 5)}
                      ownerId={msg?.senderId}
                    />
                  ) : (
                    <ChatBubble
                      id={msg?.id}
                      msg={msg?.content}
                      msgFiles={msgFiles}
                      timestamp={msg?.creationTime
                        ?.split("T")[1]
                        .substring(0, 5)}
                      isRead={msg?.isRead}
                      connection={props?.connection}
                    />
                  )}
                </div>
              );
            } else {
              return (
                <div key={msg?.id}>
                  {msg?.senderId !== currentMember?.currentMemberId ? (
                    <SenderChatBubble
                      key={msg?.id}
                      id={msg?.id}
                      msg={msg?.content}
                      msgFiles={msgFiles}
                      avatar={TemplateCardImg?.srcService_logo}
                      timestamp={msg?.creationTime
                        ?.split("T")[1]
                        .substring(0, 5)}
                      ownerId={msg?.senderId}
                    />
                  ) : (
                    <ChatBubble
                      key={msg?.id}
                      id={msg?.id}
                      msg={msg?.content}
                      msgFiles={msgFiles}
                      timestamp={msg?.creationTime
                        ?.split("T")[1]
                        .substring(0, 5)}
                      isRead={msg?.isRead}
                      connection={props?.connection}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
        <div className="type-of-answer">
          <ServiceQAMenu
            key={"QA-menu-1"}
            title="最新消息"
            type={0}
            setMessages={setMessages}
            handleScrollToBottom={handleScrollToBottom}
          />
          <ServiceQAMenu
            key={"QA-menu-2"}
            title="常見問答"
            type={1}
            setMessages={setMessages}
            handleScrollToBottom={handleScrollToBottom}
          />
          <ServiceQAMenu
            key={"QA-menu-3"}
            title="關於我們"
            type={2}
            setMessages={setMessages}
            handleScrollToBottom={handleScrollToBottom}
          />
          {!isAtBottom && (
            <div
              className="rush-to-bottom-btn"
              onClick={handleScrollToBottom}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              {isHover ? (
                <img
                  src={TemplateCardImg.srcScroll_down_icon}
                  alt="rush-down"
                />
              ) : (
                <img
                  src={TemplateCardImg.srcScroll_down_opacity_icon}
                  alt="rush-down"
                />
              )}
            </div>
          )}
        </div>
      </div>
      <ReplyControl
        connection={props?.connection}
        rushDown={handleScrollToBottom}
      />
    </>
  );
};

const ServiceChatBox = forwardRef(ServiceChatBoxFunction);

export default ServiceChatBox;
