import axios from "axios";
import { baseUrl } from "./axiosInstance";

// api to get questions for menu on service center page
export const getServiceQuestions = async (type: number) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/announcement/get-Announcements?type=${type}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get Service Questions Failed]: ", error);
  }
};

// api to get answer for QA on service center page
export const getServiceAnswer = async (id: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/announcement/get-AnnouncementDetails-Api?announcementId=${id}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get Service Answer Failed]: ", error);
  }
};
