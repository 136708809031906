//react
import { useState, useEffect } from "react";
//api
import { getBalanceOfPostAndProd } from "../../../api/membership";
import { getOrderHistory } from "../../../api/membership";
import { getHasOrderActive } from "../../../api/membership";
//components
import HistoryTable from "../../../components/HistoryTable/HistoryTable";
//context
import { useAuth } from "../../../context/AuthContext";
//type
import { TypeBalanceOfPostAndProd } from "../../../types/membership";
import { TypeOrderHistory } from "../../../types/membership";
//style
import "./PurchasedDeals.scss";

const PurchasedDeals = () => {
  const [balanceData, setBalanceData] = useState<TypeBalanceOfPostAndProd>({
    availPosts: 0,
    availPostsProd: 0,
    noOfPosts: 0,
    noOfPostsProd: 0,
  });

  const [hasPlan, setHasPlan] = useState(false);
  const [historyData, setHistoryData] = useState<TypeOrderHistory[]>([]);
  const expiredDate = historyData?.slice(-1)?.[0]?.expiredDate;

  // context - memberId
  const { currentMember } = useAuth();

  useEffect(() => {
    const getUserBalanceAsync = async () => {
      try {
        const balance = await getBalanceOfPostAndProd(
          currentMember?.currentMemberId
        );
        setBalanceData(balance);
      } catch (error) {
        console.log(error);
      }
    };

    getUserBalanceAsync();
  }, [currentMember?.currentMemberId]);

  useEffect(() => {
    const getOrderHistoryAsync = async () => {
      try {
        const history = await getOrderHistory(currentMember?.currentMemberId);
        setHistoryData(history);
      } catch (error) {
        console.log(error);
      }
    };

    getOrderHistoryAsync();
  }, [currentMember?.currentMemberId]);

  useEffect(() => {
    const checkHasPlanAsync = async () => {
      const res = await getHasOrderActive(currentMember?.currentMemberId);

      setHasPlan(res?.data);
    };

    checkHasPlanAsync();
  }, [currentMember?.currentMemberId]);

  return (
    <div className="purchased-deals-container">
      <div className="post-balance-sec">
        <div className="sec-title">
          <span className="r-24">目前額度</span>
          <span className="r-14 current-expiry-date">
            {hasPlan &&
              `（ 到期日： ${expiredDate
                ?.substring(0, 10)
                ?.replaceAll("-", "/")} ）`}
          </span>
        </div>
        <div className="balance-group r-14">
          <div className="counts-box published-counts">
            <span>剩餘發佈數</span>
            <span>{balanceData?.availPosts}</span>
          </div>
          <div className="counts-box edited-counts">
            <span>剩餘後製數</span>
            <span>{balanceData?.availPostsProd}</span>
          </div>
        </div>
      </div>
      <div className="purchase-history-sec">
        <div className="sec-title r-24">
          <span>歷史紀錄</span>
        </div>
        <div className="history-group r-14">
          <HistoryTable historyData={historyData} />
        </div>
      </div>
    </div>
  );
};

export default PurchasedDeals;
