//react
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
//components
import MainHeader from "../../components/MainHeader/MainHeader";
import AllRandomPosts from "../../components/AllRandomPosts/AllRandomPosts";
import AllPosts from "../../components/AllPosts/AllPosts";
import FilteredPosts from "../../components/FilteredPosts/FilteredPosts";
import SearchedPosts from "../../components/SearchedPosts/SearchedPosts";
import PostPopupModel from "../../components/PostPopupModel/PostPopupModel";
import BackToTopArrow from "../../components/BackToTopArrow/BackToTopArrow";
//context
import { useFilter } from "../../context/HomeFilterContext";
import { useNotifi } from "../../context/NotifiProvider";
import { useAuth } from "../../context/AuthContext";
//api
import { thirdPartyLogin } from "../../api/auth";
//style
import "./HomePage.scss";

const HomePage: React.FC = () => {
  const [isShow, setIsShow] = useState(false);
  const location = useLocation();

  // context - Control filter values
  const {
    postCotent,
    setPostContent,
    setPurpose,
    setRegion,
    setHouseType,
    setPrice,
    setLayout,
    setPropertyAge,
    setSearchKey,
  } = useFilter();

  const { storeToken } = useAuth();

  // Search keyword
  const [keyWord, setKeyword] = useState("");

  // Line Auth
  useEffect(() => {
    // Extract query parameters from the URL
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const LINE_LOGIN_CHANNEL_ID = "2003791987";
    const LINE_LOGIN_REDIRECT_URI = "https://highlight11.com/";
    const LINE_LINE_LOGIN_CHANNEL_SECRET = "81ddf2cc26ac554a9b37fa6e8da86f23";
    const authString = `${LINE_LOGIN_CHANNEL_ID}:${LINE_LINE_LOGIN_CHANNEL_SECRET}`;
    const basicAuth = btoa(authString);

    const exchangeCodeForToken = async (code: string) => {
      const data = {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: LINE_LOGIN_REDIRECT_URI,
      };

      try {
        const response = await axios.post(
          "https://api.line.me/oauth2/v2.1/token",
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Basic ${basicAuth}`,
            },
          }
        );
        const accessToken = response.data.access_token;
        console.log(accessToken); // test get access token from Line
        if (accessToken) {
          try {
            const res = await thirdPartyLogin(accessToken, 2);
            storeToken({ token: res?.token });
          } catch (error) {
            console.error(error);
          }
        }
        return accessToken;
      } catch (error) {
        console.error("Error exchanging code for token:", error);
        throw new Error("Failed to exchange code for token");
      }
    };

    if (code) {
      exchangeCodeForToken(code);
    }
  }, [location.search]);

  // Clear filter to make sure to show all post when entering page initially
  useEffect(() => {
    setPostContent?.("All");
    setPurpose?.("sell");
    setRegion?.([]);
    setHouseType?.([]);
    setPrice?.([]);
    setLayout?.([]);
    setPropertyAge?.([]);
    setSearchKey?.(0);
  }, []);

  // notification
  const { postId, notifiUsed, setPostId } = useNotifi();

  useEffect(() => {
    if (notifiUsed) {
      setIsShow(true);
    }
  }, [notifiUsed]);

  // shared link through Line, Facebook and others to launch certain post on page
  useEffect(() => {
    const currentPgaeUrl = window.location.href;

    const postId = currentPgaeUrl?.includes("/post/")
      ? currentPgaeUrl
          ?.slice(currentPgaeUrl?.lastIndexOf("/") + 1)
          ?.substring(0, 36)
      : "";

    if (postId) {
      setPostId?.(postId);
      setIsShow(true);
    }
  }, []);

  return (
    <>
      <MainHeader keyWord={keyWord} setKeyword={setKeyword} />
      <div className="site-home-container main-body-spacing">
        <div className="house-list-container">
          {postCotent === "All" && <AllPosts />}
          {postCotent === "Filter" && <FilteredPosts />}
          {postCotent === "Search" && <SearchedPosts keyWord={keyWord} />}
          <BackToTopArrow />
        </div>
      </div>
      {isShow && (
        <PostPopupModel postId={postId} setIsShow={(show) => setIsShow(show)} />
      )}
    </>
  );
};

export default HomePage;
