import axios from "axios";
import { baseUrl } from "./axiosInstance";

// api to get recommended hashtag while typing
export const getRecommendedTag = async (hashtag: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${baseUrl}/app/hashtag/get-TopThreeHashtags`, {
      params: {
        hashtag: hashtag,
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get Recommanded Hashtag Failed]: ", error);
  }
};

// api to fetch posts related to hashtag
export const getHashtagPosts = async ({
  currentMemberId,
  skipCount,
  hashtag,
}: {
  currentMemberId: string;
  skipCount: number;
  hashtag: string;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${baseUrl}/app/post/get-postsByHashtag-api`, {
      params: {
        currentMember: currentMemberId,
        skipCount: skipCount,
        maxResultCount: 10,
        hashtag: hashtag,
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get HashTag Posts Failed]: ", error);
  }
};
