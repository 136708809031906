//react
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
//Type
import { TypeMessage } from "../types/chats";

interface DefaultChatType {
  selectedRoomId: string;
  chat: string;
  messages: TypeMessage[];
  receiverId: string;
  fetchAgain: boolean;
  allFiles: File[];
  justEnter: boolean;
  setSelectedRoomId?: Dispatch<SetStateAction<string>>;
  setChat?: Dispatch<SetStateAction<string>>;
  setMessages?: Dispatch<SetStateAction<TypeMessage[]>>;
  setReceiverId?: Dispatch<SetStateAction<string>>;
  setFetchAgain?: Dispatch<SetStateAction<boolean>>;
  setAllFiles?: Dispatch<SetStateAction<File[]>>;
  setJustEnter?: Dispatch<SetStateAction<boolean>>;
}

const defaultChatContext: DefaultChatType = {
  selectedRoomId: "",
  chat: "",
  messages: [],
  receiverId: "",
  fetchAgain: false,
  allFiles: [],
  justEnter: false,
};

const ChatContext = createContext<DefaultChatType>(defaultChatContext);

export const useChat = () => useContext(ChatContext);

interface ChatProviderProps {
  children: ReactNode; // This is the type for children
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [selectedRoomId, setSelectedRoomId] = useState(""); // used to set current chatroom id
  const [chat, setChat] = useState(""); // used for input of replying
  const [messages, setMessages] = useState<TypeMessage[]>([]); //used to store realtime messages
  const [receiverId, setReceiverId] = useState(""); // used to set opponent (whom user is replying to)
  const [fetchAgain, setFetchAgain] = useState(false); // used to refetch list of chatrooms
  const [allFiles, setAllFiles] = useState<File[]>([]); // used to upload files for message
  const [justEnter, setJustEnter] = useState(false);

  return (
    <ChatContext.Provider
      value={{
        selectedRoomId,
        setSelectedRoomId,
        chat,
        messages,
        justEnter,
        setChat,
        setMessages,
        receiverId,
        setReceiverId,
        fetchAgain,
        setFetchAgain,
        allFiles,
        setAllFiles,
        setJustEnter,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
