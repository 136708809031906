//react
import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
//components
import AuthPopupModel from "../AuthPopupModel/AuthPopupModel";
import ServicePolicyPopupModel from "../ServicePolicyPopupModel/ServicePolicyPopupModel";
import PrivacyPolicyPopupModel from "../PrivacyPolicyPopupModel/PrivacyPolicyPopupModel";
//api
import { getUserInfo } from "../../api/users";
// context
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//type
import { TypeUserInfo } from "../../types/user";
//imgs
import { TemplateNavBarImg } from "../../assets/data/imagesData";
//style
import "./MainNavBar.scss";

const myInfoTabs = [
  {
    title: "編輯資訊",
    path: "/editinfo",
    key: "subTab-1",
  },
  {
    title: "會員方案",
    path: "/membership",
    key: "subTab-2",
  },
  {
    title: "客服中心",
    path: "/support-center",
    key: "subTab-3",
  },
];

const MainNavBar: React.FC = () => {
  const [showMyList, setShowMyList] = useState(false);
  const [userInfo, setUserInfo] = useState<TypeUserInfo>({
    accountType: "",
    availablePost: 0,
    availablePostProduction: 0,
    backgroundImage: "",
    personalAvatar: "",
    personalIntroduction: "",
    userName: "",
  });

  // auth - context
  const { isAuthenticated, logout, currentMember, navAvatar, setShowAuth } =
    useAuth();

  const { hasContent, triggerPopup } = usePost();

  //navigation
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const navTabs = isAuthenticated
    ? [
        {
          title: "首頁",
          icon: TemplateNavBarImg.srcHome_icon,
          path: "/",
          key: "tab-1",
        },
        {
          title: "熱門",
          icon: TemplateNavBarImg.srcPopular_icon,
          path: "/trending-posts",
          key: "tab-2",
        },
        {
          title: "發佈",
          icon: TemplateNavBarImg.srcPublish_icon,
          path: "/publish",
          key: "tab-3",
        },
        {
          title: "訊息",
          icon: TemplateNavBarImg.srcMessage_icon,
          path: "/message-main",
          key: "tab-4",
        },
      ]
    : [
        {
          title: "首頁",
          icon: TemplateNavBarImg.srcHome_icon,
          path: "/",
          key: "tab-1",
        },
        {
          title: "熱門",
          icon: TemplateNavBarImg.srcPopular_icon,
          path: "/trending-posts",
          key: "tab-2",
        },
        {
          title: "會員方案",
          icon: TemplateNavBarImg.srcMembership_icon,
          path: "/membership",
          key: "tab-ˇ",
        },
      ];

  useEffect(() => {
    const getUserInfoAsync = async () => {
      console.log(currentMember?.currentMemberId);
      try {
        const info = await getUserInfo(currentMember?.currentMemberId);
        setUserInfo(info);
      } catch (error) {
        console.error(error);
      }
    };

    getUserInfoAsync();
  }, [currentMember?.currentMemberId, navAvatar]);

  return (
    <div className="navbar-primary-container">
      <div
        className="nav-logo-container"
        onClick={() => {
          !hasContent ? navigate("/") : triggerPopup("/");
        }}
      >
        <img src={TemplateNavBarImg.srcLogo_nav} alt="logo" />
        {/* <div className="r-12">門澄數位有限公司</div> */}
      </div>
      <div className="nav-tab-container">
        <ul className="tab-links-container">
          {navTabs.map((tab) => {
            return (
              <li
                key={tab.key}
                className={`tab-link ${
                  pathname === tab.path ? "active-tab" : ""
                }`}
                onClick={() => {
                  setShowMyList(false);
                  !hasContent ? navigate(tab.path) : triggerPopup(tab.path);
                }}
              >
                <img src={tab.icon} alt="tab-icon" />
                <span className="sb-16">{tab.title}</span>
              </li>
            );
          })}
        </ul>
      </div>
      {isAuthenticated && (
        <div className="my-info-actions">
          <div
            className={`my-info-tab ${
              pathname.includes("/myinfo") ? "active-tab" : ""
            }`}
            onClick={() => {
              setShowMyList(!showMyList);
              !hasContent
                ? navigate(
                    `/myinfo/${currentMember?.currentMemberId?.substring(
                      0,
                      16
                    )}`
                  )
                : triggerPopup(
                    `/myinfo/${currentMember?.currentMemberId?.substring(
                      0,
                      16
                    )}`
                  );
            }}
          >
            <img
              className="my-pic"
              src={userInfo?.personalAvatar}
              alt="my-pic"
            />
            <span className="sb-16">我的資訊</span>
            <img
              className={`arrow-icon ${showMyList && "rotate"}`}
              src={TemplateNavBarImg.srcArrow_down_icon}
              alt="tab-icon"
            />
          </div>

          {showMyList && (
            <div className="my-info-links-group">
              <div className="vertical-bar"></div>
              <ul className="my-info-links-list">
                {myInfoTabs.map((tab) => {
                  return (
                    <li
                      key={tab.key}
                      className={`my-info-link sb-16 ${
                        pathname === tab.path ? "active-subtab" : ""
                      }`}
                      onClick={() =>
                        !hasContent
                          ? navigate(tab.path)
                          : triggerPopup(tab.path)
                      }
                    >
                      {tab.title}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
      <div className="about-us-tab-btn">
        <div
          className={`about-us-tab ${
            pathname === "/about-us" ? "active-tab" : ""
          }`}
          onClick={() =>
            !hasContent ? navigate("/about-us") : triggerPopup("/about-us")
          }
        >
          <img src={TemplateNavBarImg.srcAbout_us_icon} alt="tab-icon" />
          <span className="sb-16">關於我們</span>
        </div>
      </div>
      <div className="auth-btn-container">
        {isAuthenticated ? (
          <button className="sb-16 primary-btn" onClick={() => logout()}>
            登出
          </button>
        ) : (
          <button
            className="sb-16 primary-btn"
            onClick={() => setShowAuth?.(true)}
          >
            登入
          </button>
        )}
      </div>
    </div>
  );
};

export default MainNavBar;
