//React
import { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
//api
import { getServiceQuestions, getServiceAnswer } from "../../api/service";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
//type
import { TypeQuestion } from "../../types/service";
import { TypeServiceChat, TypeMessage } from "../../types/chats";
//style
import "./ServiceQAMenu.scss";

interface ServiceQAMenuProps {
  title: string;
  type: number;
  setMessages: Dispatch<SetStateAction<TypeMessage[]>>;
  handleScrollToBottom: () => void;
}

const ServiceQAMenu: React.FC<ServiceQAMenuProps> = (props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [toBottom, setToBottom] = useState(false);

  const [questions, setQuestions] = useState([]);

  // generate current local time in format that matches api data
  function getCurrentLocalTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const milliseconds = now.getMilliseconds().toString().padStart(3, "0");

    const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}0000`;

    return formattedTime;
  }

  const handeGetAnswer = async (id: string) => {
    const answer = await getServiceAnswer(id);

    if (answer) {
      props.setMessages((prev) => [
        ...prev,
        {
          chatroomId: "",
          content: answer.content,
          creationTime: getCurrentLocalTime(),
          fileContent: answer.image,
          id: answer.id,
          isRead: true,
          isSenderDeleted: false,
          receiverAvatar: "",
          receiverId: "",
          receiverName: "",
          senderAvatar: "",
          senderId: "fb7d07d7-2125-ee48-e161-3a12b8c34640",
          senderName: "",
        },
      ]);
      setToBottom(!toBottom);
    }
  };

  useEffect(() => {
    props?.handleScrollToBottom();
  }, [toBottom]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  useEffect(() => {
    const getServiceQuestionsAsync = async () => {
      try {
        const questions = await getServiceQuestions(props.type);
        console.log(questions);
        setQuestions(questions);
      } catch (error) {
        console.log(error);
      }
    };
    getServiceQuestionsAsync();
  }, []);

  return (
    <div className="service-inquiry-menu-box">
      <div ref={ref}>
        <Button
          className={`inquiry-menu-btn primary-btn r-16 ${
            show && "show-active"
          }`}
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target as HTMLElement);
          }}
        >
          {props.title}
        </Button>
        <Overlay
          show={show}
          target={target}
          placement="top"
          container={ref}
          containerPadding={20}
        >
          <Popover>
            <Popover.Body>
              <Form>
                <ul className="inquiry-menu-list r-14">
                  {questions?.map((q: TypeQuestion) => (
                    <li onClick={() => handeGetAnswer(q?.id)}>{q?.title}</li>
                  ))}
                </ul>
              </Form>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </div>
  );
};

export default ServiceQAMenu;
