//React
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//component
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import MemberPosts from "../../components/MemberPosts/MemberPosts";
import BackToTopArrow from "../../components/BackToTopArrow/BackToTopArrow";
//context
import { useNotifi } from "../../context/NotifiProvider";
//api
import { getUserInfo, getUserCountsInfo } from "../../api/users";
//type
import { TypeUserInfo, TypeCountsInfo } from "../../types/user";
//style
import "./UserInfoPage.scss";

const UserInfoPage = () => {
  const [userInfo, setUserInfo] = useState<TypeUserInfo>({
    accountType: "",
    availablePost: 0,
    availablePostProduction: 0,
    backgroundImage: "",
    personalAvatar: "",
    personalIntroduction: "",
    userName: "",
  });
  const [countsInfo, setCountsInfo] = useState<TypeCountsInfo>({
    memberFans: 0,
    memberPosts: 0,
    postLikes: 0,
  });
  const [userId, setUserId] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.userId) {
      const getUserInfoAsync = async () => {
        try {
          const info = await getUserInfo(location?.state?.userId);
          setUserInfo(info);
          setUserId(location?.state?.userId);
        } catch (error) {
          console.error(error);
        }
      };

      const getUserCountsAsyc = async () => {
        try {
          const counts = await getUserCountsInfo({
            memberId: location?.state?.userId,
            includePrivate: false,
          });

          setCountsInfo(counts);
        } catch (error) {
          console.log(error);
        }
      };

      getUserInfoAsync();
      getUserCountsAsyc();
    }
  }, [location?.state?.userId]);

  //Notification
  const { notifiUsed, followerId, setNotifiUsed } = useNotifi();

  useEffect(() => {
    if (notifiUsed) {
      const getUserInfoAsync = async () => {
        try {
          const info = await getUserInfo(followerId);
          setUserInfo(info);
          setUserId(followerId);
        } catch (error) {
          console.error(error);
        }
      };

      const getUserCountsAsyc = async () => {
        try {
          const counts = await getUserCountsInfo({
            memberId: followerId,
            includePrivate: false,
          });

          setCountsInfo(counts);
        } catch (error) {
          console.log(error);
        }
      };

      getUserInfoAsync();
      getUserCountsAsyc();
    }

    return () => {
      setNotifiUsed?.(false); //notification
    };
  }, [followerId]);

  return (
    <>
      <TitleHeader title="用戶資訊" />
      <div className="myinfo-main-container main-body-spacing">
        <div className="user-profile-sec">
          <ProfileCard
            userInfo={userInfo}
            userId={userId}
            countsInfo={countsInfo}
          />
        </div>
        <div className="user-posts-container">
          <MemberPosts memberId={userId} isMyPosts={false} />
          <BackToTopArrow />
        </div>
      </div>
    </>
  );
};

export default UserInfoPage;
