//react
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface DefaultPostType {
  id: string;
  isLikedText: string;
  reportShow: boolean;
  reportId: string;
  reportType: number;
  reportedId: string;
  content: string;
  refetchProfile: boolean;
  hasContent: boolean;
  path: string;
  showPopup: boolean;
  memberId: string;
  setId?: Dispatch<SetStateAction<string>>;
  setIsLikedText?: Dispatch<SetStateAction<string>>;
  setReportShow?: Dispatch<SetStateAction<boolean>>;
  setRepostId?: Dispatch<SetStateAction<string>>;
  setReportType?: Dispatch<SetStateAction<number>>;
  setReportedId?: Dispatch<SetStateAction<string>>;
  setContent?: Dispatch<SetStateAction<string>>;
  setRefetchProfile?: Dispatch<SetStateAction<boolean>>;
  setHasContent?: Dispatch<SetStateAction<boolean>>;
  setPath?: Dispatch<SetStateAction<string>>;
  setMemberId?: Dispatch<SetStateAction<string>>;
  triggerPopup: (path: string) => void;
}

const defaultPostContext: DefaultPostType = {
  id: "",
  isLikedText: "",
  reportShow: false,
  reportId: "",
  reportType: 0,
  reportedId: "",
  content: "",
  refetchProfile: false,
  hasContent: false,
  path: "",
  showPopup: false,
  memberId: "",
  triggerPopup: () => {},
};

const PostContext = createContext<DefaultPostType>(defaultPostContext);

export const usePost = () => useContext(PostContext);

interface PostProviderProps {
  children: ReactNode; // This is the type for children
}

export const PostProvider: React.FC<PostProviderProps> = ({ children }) => {
  const [id, setId] = useState("");
  const [isLikedText, setIsLikedText] = useState("");
  const [reportShow, setReportShow] = useState(false);
  const [reportId, setRepostId] = useState("");
  const [reportType, setReportType] = useState(0);
  const [reportedId, setReportedId] = useState("");
  const [content, setContent] = useState("");
  const [refetchProfile, setRefetchProfile] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const [path, setPath] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [memberId, setMemberId] = useState(""); // to control refetch of MemberPosts component

  const triggerPopup = (path: string) => {
    setPath(path);
    setShowPopup(!showPopup);
  };

  return (
    <PostContext.Provider
      value={{
        id,
        setId,
        isLikedText,
        setIsLikedText,
        reportShow,
        setReportShow,
        reportId,
        setRepostId,
        reportType,
        setReportType,
        reportedId,
        setReportedId,
        content,
        setContent,
        refetchProfile,
        setRefetchProfile,
        hasContent,
        setHasContent,
        path,
        setPath,
        showPopup,
        memberId,
        setMemberId,
        triggerPopup,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
