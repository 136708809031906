import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import { TypePayload } from "../types/auth";
import { getCheckUserStatus } from "../api/auth";

const useTokenCheck = (
  setIsAuthenticated: (value: boolean) => void,
  setPayload: (payload: TypePayload) => void,
  setTimeout: (value: boolean) => void,
  deleteToken: (tokenName: string) => void,
  setIsBanned: (value: boolean) => void
) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // detect if token is in cookies when directory of url changes
  useEffect(() => {
    // get token stored in cookies
    const getTokenValue = (tokenName: string) => {
      // Splitting the cookie string into an array of individual cookies
      const cookies = document.cookie.split("; ");

      // Loop through each cookie pair
      for (const cookie of cookies) {
        // Split the pair into key and value
        const [key, value] = cookie.split("=");

        // Check if the key matches the one we're looking for
        if (key === tokenName) {
          return decodeURIComponent(value);
        }
      }

      // Return null if token is not found
      return null;
    };

    const authToken = getTokenValue("shortToken");

    if (authToken) {
      const tempPayload: any = decodeToken(authToken);

      // function to logout banned user
      const checkIsBannedAsync = async (memberId: string) => {
        try {
          const valid = await getCheckUserStatus(memberId);

          if (valid === false) {
            setIsBanned(true);
            deleteToken("shortToken");
            setIsAuthenticated(false);
            setPayload({ CurrentMemberId: "" });
            navigate(`/`);
          } else {
            setIsAuthenticated(true);
            setPayload(tempPayload);
          }
        } catch (error) {
          console.log(error);
        }
      };

      // stay logged in accrodding to expiration of token
      const isExpired =
        dayjs.unix(tempPayload.exp).diff(dayjs(), "second") < 3600;

      if (isExpired) {
        setTimeout(true);
        deleteToken("shortToken");
        setIsAuthenticated(false);
        setPayload({ CurrentMemberId: "" });
        navigate(`/`);
      } else {
        // check if user is not banned from using
        checkIsBannedAsync(tempPayload?.CurrentMemberId);
      }
    } else {
      setIsAuthenticated(false);
      setPayload({ CurrentMemberId: "" });
    }
  }, [pathname]);
};

export default useTokenCheck;
