import axios from "axios";
import { baseUrl } from "./axiosInstance";
//type
import { TypeUserInfoSubmit } from "../types/user";

// api to get users profile information
export const getUserInfo = async (userId: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${baseUrl}/app/member/get-member?Id=${userId}`);
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get User Information Failed]: ", error);
  }
};

// api to update users profile information
export const postUpdateUserInfo = async ({
  id,
  UserName,
  PersonalIntroduction,
  personalAvatar,
  backgroundImage,
  backgoundImageMobile,
  businessCard,
}: TypeUserInfoSubmit) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/member/update-member-api`,
      {
        id,
        UserName,
        PersonalIntroduction,
        personalAvatar,
        backgroundImage,
        backgoundImageMobile,
        businessCard,
      },
      { headers: { "Content-type": "multipart/form-data" } }
    );
    console.log(data);
    return data.success;
  } catch (error) {
    console.error("[Update User Information Failed]: ", error);
  }
};

// api to get list of followers of an user
export const getListOfFollowers = async (userId: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/follower/get-listOfMemberWhoFollowMe?currentMemberId=${userId}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get List of Followers Failed]: ", error);
  }
};

// api to check if current user is following a certain user
export const postIsFollowing = async ({
  FollowerId, //追蹤者
  FollowedId, //要追蹤的對象
}: {
  FollowerId: string;
  FollowedId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/follower/is-currently-following?FollowerId=${FollowerId}&FollowedId=${FollowedId}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Post Is Currently Following Failed]: ", error);
  }
};

// api to follow or unfollow a user
export const postFollowOrUnfollow = async ({
  FollowerId, //追蹤者
  FollowedId, //要追蹤的對象
}: {
  FollowerId: string;
  FollowedId: string;
}) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${baseUrl}/app/follower/follow-member?FollowerId=${FollowerId}&FollowedId=${FollowedId}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Post Is Currently Following Failed]: ", error);
  }
};

// api to get users counts of follower, Post and Like
export const getUserCountsInfo = async ({
  memberId,
  includePrivate,
}: {
  memberId: string;
  includePrivate: boolean;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/member/get-FansPostsLikesCount-api?memberId=${memberId}&includePrivate=${includePrivate}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get User Information Failed]: ", error);
  }
};

// api to get users notification
export const getUserNotification = async (memberId: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/userNotifications/get-MonthsNotificationsByMemberId?memberId=${memberId}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Get Notification Failed]: ", error);
  }
};

// api to update notification to read one
export const postIsReadNotifi = async (notificationId: string) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/userNotifications/update-Notifications-To-Read?notificationId=${notificationId}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Post Update Notification to Read Failed]: ", error);
  }
};

// api to assosiate user id with device token
export const postUpateDeviceToken = async ({
  memberId,
  token,
}: {
  memberId: string;
  token: string;
}) => {
  console.log(token);
  try {
    const data = await axios.post(
      `${baseUrl}/app/firebase/create-Or-Update-DeviceToken?memberId=${memberId}&token=${token}`
    );
    console.log(data);
    return data;
  } catch (error) {
    console.error("[Post Update Device Token Failed]: ", error);
  }
};
