//react
import { useRef, useCallback } from "react";
import { useInfiniteQuery } from "react-query";
//components
import HouseItemCard from "../HouseItemCard/HouseItemCard";
//api
import { getHashtagPosts } from "../../api/hashtag";
//context
import { useFilter } from "../../context/HomeFilterContext";
import { useAuth } from "../../context/AuthContext";
//type
import { TypePost } from "../../types/posts";

const HashtagPosts: React.FC = () => {
  //context
  const { selectedHashtag } = useFilter();

  //memberId
  const { currentMember } = useAuth();

  // Implement infinite scroll with react query
  const {
    fetchNextPage, //function
    hasNextPage, // boolean
    isFetchingNextPage, // boolean
    data, // response from api
    status,
    error,
  } = useInfiniteQuery<TypePost[], Error>(
    ["/posts", selectedHashtag],
    ({ pageParam = 0 }) =>
      getHashtagPosts({
        currentMemberId: currentMember?.currentMemberId,
        skipCount: pageParam === 0 ? pageParam : (pageParam - 1) * 10,
        hashtag: selectedHashtag,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.length ? allPages.length + 1 : undefined;
      },
    }
  );

  const intObserver = useRef<IntersectionObserver | null>(null);
  const lastPostRef = useCallback(
    (post: Element | null) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          //console.log("We are near the last post");
          fetchNextPage();
        }
      });

      if (post) intObserver.current.observe(post);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  // To know error
  if (status === "error" && error) {
    console.log(error.message);
  }

  return (
    <>
      {data?.pages.map((pg) => {
        return pg?.map((post, i) => {
          const isLastPost = pg.length === i + 1;
          return (
            <HouseItemCard
              ref={isLastPost ? lastPostRef : undefined}
              key={post.id}
              post={post}
            />
          );
        });
      })}
    </>
  );
};

export default HashtagPosts;
