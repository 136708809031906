//react
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//components
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import Input from "../../components/Input/Input";
import TextAreaWithHashtag from "../../components/TextAreaWithHashtag/TextAreaWithHashtag";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
import LinearProgressWithLabel from "../../components/LinearProgressWithLabel/LinearProgressWithLabel";
import EditWarningPopup from "../../components/EditWarningPopup/EditWarningPopup";
//context
import { useNotifi } from "../../context/NotifiProvider";
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//api
import { postCreatePost, getPostDetail, postEditPost } from "../../api/posts";
//hooks
import useConfirmNavigation from "../../hooks/useConfirmNavigation";
//data
import { filterData } from "../../assets/data/filterData";
import { imageFormats } from "../../assets/data/fileFormats";
//bootstrap
import Carousel from "react-bootstrap/Carousel";
import { CarouselRef } from "react-bootstrap/Carousel";
//img
import {
  TemplateNavBarImg,
  TemplateOtherImg,
} from "../../assets/data/imagesData";
//style
import "./EditPostPage.scss";

const EditPostPage = () => {
  // implement navigation and bring data back onto previous page
  const navigate = useNavigate();
  // data brought from previous page
  const location = useLocation();

  // control popup when user has no valid plan for public post
  const [notEditable, setNotEditable] = useState(false);
  // control popup when api of creating or editing post has error ocuurs
  const [hasErrorRes, setHasErrorRes] = useState(false);

  // percentage of progression
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowPorogress] = useState(false);

  // Ref to cache file URLs - prevent video from reloding when typing on input
  const fileUrlsRef = useRef<Map<string, string>>(new Map());
  const [fileRef, setfileRef] = useState(false);

  // textarea - ref to make sure focusing after clicking hasgtag btn
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  //context
  const { setPostProcessing } = useNotifi();

  // memberId
  const { currentMember } = useAuth();

  // hashtag - recommended keywords
  const [hashtags, setHashtags] = useState<string[]>([]);

  const handleHashtag = (hashtag: string) => {
    setDescr((prev) => {
      setHashtags([]);
      return prev.slice(0, prev?.lastIndexOf("#")) + hashtag + " ";
    });

    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  // type of post
  const [postType, setPostType] = useState("");
  // either creating or editing
  const [editState, setEditState] = useState("");

  const [isShow, setIsShow] = useState(false);

  // thumbnail state
  const [showThumbnail, setShowThumbnail] = useState(false);

  // Store Files fetched from api for img and video editing
  const [imgFiles, setImgFiles] = useState<string[]>([]);
  const [videoFile, setVideoFile] = useState("");
  const [coverImg, setCoverImg] = useState("");

  // Store data for request api
  const [title, setTitle] = useState("");
  const [descr, setDescr] = useState("");
  const [purpose, setPurpose] = useState("sell");
  const [privacy, setPrivacy] = useState("");
  const [region, setRegion] = useState("");
  const [houseType, setHouseType] = useState("");
  const [price, setPrice] = useState("");
  const [layout, setLayout] = useState("");
  const [propertyAge, setPropertyAge] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [removedCover, setRemovedCover] = useState("");

  //input Validation
  const [titleError, setTitleError] = useState("");
  const [descrError, setDescrError] = useState("");
  const [privacyError, setPrivacyError] = useState("");
  const [thumbnailError, setThumbnailError] = useState("");

  const handleThumbnailUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      setThumbnail(file);
      coverImg && setRemovedCover(coverImg);
      setCoverImg("");
      setThumbnailError("");
    }
  };

  //handle Input Validation
  const handleTitleInput = (titleInputValue: string) => {
    if (titleInputValue.length > 39) {
      setTitleError("標題不可超過39個字");
    } else if (titleInputValue.length <= 0) {
      setTitleError("標題至少為一個字");
    } else {
      setTitleError("");
    }
    setTitle(titleInputValue);
  };

  const handleDescrInput = (textInputValue: string) => {
    if (textInputValue.length <= 0) {
      setDescrError("內容不可空白");
    } else {
      setDescrError("");
    }

    setDescr(textInputValue);
  };

  // Create Post
  const handleCreatePost = async () => {
    const purposeConvert = purpose === "sell" ? 1 : 0;
    const privacyConvert = privacy === "私人" ? 1 : 0;

    let valid = true;

    if (title.length > 39) {
      setTitleError("標題不可超過39個字");
      valid = false;
    } else if (title.length <= 0) {
      setTitleError("標題至少為一個字");
      valid = false;
    } else {
      setTitleError("");
    }

    if (descr.length <= 0) {
      setDescrError("內容不可空白");
      valid = false;
    } else {
      setDescrError("");
    }

    if (privacy.length <= 0) {
      setPrivacyError("請選擇觀看權限");
      valid = false;
    } else {
      setPrivacyError("");
    }

    const hasCover = thumbnail || coverImg;

    if (showThumbnail && !hasCover) {
      setThumbnailError("請上傳貼文封面");
      valid = false;
    } else {
      setThumbnailError("");
    }

    if (!valid) return;

    const formData = new FormData();

    formData.append("MemberId", currentMember?.currentMemberId);
    formData.append("Purpose", purposeConvert.toString());
    formData.append("Privacy", privacyConvert.toString());
    formData.append("Title", title);
    formData.append("Content", descr);
    formData.append("Region", region);
    formData.append("HouseType", houseType);
    formData.append("Price", price);
    formData.append("Layout", layout);
    formData.append("PropertyAge", propertyAge);

    if (showThumbnail) {
      formData.append("Type", "1");
      uploadedFiles?.length > 1 && formData.append("Series", true.toString());
      thumbnail && formData.append("coverImage", thumbnail);
      removedCover && formData.append("coverImageToBeDeleted", removedCover);
    } else {
      formData.append("Type", "0");
      formData.append("Series", false.toString());
      // delete cover when post of single video turns into post with images
      coverImg && formData.append("coverImageToBeDeleted", coverImg);
    }

    uploadedFiles?.forEach((file: File) => {
      if (file?.type?.includes("image")) {
        formData.append("images", file);
      } else {
        formData.append("videos", file);
      }
    });

    if (location?.state?.status === "editing") {
      formData.append("Id", location?.state?.postId);
      // postType === "image" &&
      //   location?.state?.removedFiles?.forEach((img: string) => {
      //     formData.append("imagesToBeDeleted", img);
      //   });

      // delete files of post in db
      showThumbnail
        ? location?.state?.removedFiles?.length &&
          location?.state?.removedFiles?.forEach((file: string) => {
            postType === "image" &&
              !coverImg &&
              formData.append("imagesToBeDeleted", file);
            postType === "image" &&
              coverImg &&
              formData.append("videosToBeDeleted", file);
            postType !== "image" &&
              formData.append("mergeVideosToBeDeleted", file);
          })
        : location?.state?.removedFiles?.length &&
          location?.state?.removedFiles?.forEach((file: string) => {
            coverImg
              ? formData.append("videosToBeDeleted", file)
              : formData.append("imagesToBeDeleted", file);
          });
    }

    try {
      setShowPorogress(true);
      // control progress bar - material ui
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 99 ? 99 : prevProgress + 1
        );
      }, 200); //150

      const res =
        location?.state?.status === "creating"
          ? ((await postCreatePost(formData)) as any)
          : ((await postEditPost(formData)) as any);

      if (res?.data?.success) {
        // control progress bar (to 100%) - material ui
        clearInterval(timer);
        setProgress(100);

        setTimeout(() => {
          navigate(
            `/myinfo/${currentMember?.currentMemberId?.substring(0, 16)}`
          );
          //response後貼文處理中彈窗
          uploadedFiles.length > 1 &&
            showThumbnail &&
            setPostProcessing?.(true);
        }, 1000);
      } else if (res?.response?.data?.message === "Your order is expired.") {
        setShowPorogress(false);
        setProgress(0);
        clearInterval(timer);
        setNotEditable(true);
      } else if (!res?.response?.data?.success) {
        setShowPorogress(false);
        setProgress(0);
        clearInterval(timer);
        setHasErrorRes(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Privacy Eorror Control
  useEffect(() => {
    if (privacy.length > 0) {
      setPrivacyError("");
    }

    if (showThumbnail && thumbnail) {
      setThumbnailError("");
    }
  }, [privacy, thumbnail]);

  // Define data from other page to pass into state
  useEffect(() => {
    const status = location?.state?.status;

    setPostType(location?.state?.postType);
    setEditState(location?.state?.status);

    if (status === "creating") {
      const uploadedFiles = location?.state?.uploadedFiles;
      setUploadedFiles(uploadedFiles);

      location?.state?.uploadedFiles?.[0]?.type.includes("video") &&
        setShowThumbnail(true);
    }

    if (status === "editing" && !location?.state?.isReEdit) {
      const postId = location?.state?.postId;
      const getPostInfoAsync = async () => {
        try {
          const post = await getPostDetail({
            postId: postId,
            currentMember: currentMember?.currentMemberId,
          });

          setTitle(post?.title);
          setDescr(post?.content);
          setPurpose(post?.purpose === 1 ? "sell" : "rent");
          setPrivacy(post?.privacy === 1 ? "私人" : "公開");
          setRegion(post?.region);
          setHouseType(post?.houseType);
          setPrice(post?.price);
          setLayout(post?.layout);
          setPropertyAge(post?.propertyAge);
          setImgFiles(post?.extraProperties?.Images);
          setVideoFile(
            post?.extraProperties?.MergedVideo
              ? post?.extraProperties?.MergedVideo[0]
              : post?.extraProperties?.Video[0]
          );
          setCoverImg(post?.extraProperties?.CoverImage);
          post?.extraProperties?.CoverImage && setShowThumbnail(true);
        } catch (error) {
          console.log(error);
        }
      };
      getPostInfoAsync();
    } else {
    }
  }, []);

  // store reEdit data - the data from re-upload page
  useEffect(() => {
    if (location?.state?.isReEdit) {
      setTitle(location?.state?.content?.title);
      setDescr(location?.state?.content?.descr);
      setPurpose(location?.state?.content?.purpose);
      setPrivacy(location?.state?.content?.privacy);
      setRegion(location?.state?.content?.region);
      setHouseType(location?.state?.content?.houseType);
      setPrice(location?.state?.content?.price);
      setLayout(location?.state?.content?.layout);
      setPropertyAge(location?.state?.content?.propertyAge);
      location?.state?.imgFiles && setImgFiles(location?.state?.imgFiles);
      setVideoFile(location?.state?.videoFile);
      setCoverImg(location?.state?.coverImg);
      setThumbnail(location?.state?.thumbnail);
      setUploadedFiles(location?.state?.uploadedFiles);
      setRemovedCover(location?.state?.removedCover);
      location?.state?.videoFile && setShowThumbnail(true);
      location?.state?.uploadedFiles?.[0]?.type.includes("video") &&
        setShowThumbnail(true);
    }
  }, []);

  // attach individual file name with file url to prevent videos from reloading while typing
  useEffect(() => {
    uploadedFiles.forEach((file: File) => {
      if (!fileUrlsRef.current.has(file.name)) {
        fileUrlsRef.current.set(file.name, URL.createObjectURL(file));
      }
    });
    setfileRef(!fileRef);
  }, [uploadedFiles]);

  useEffect(() => {
    // Cleanup cached URLs on component unmount
    const fileUrls = fileUrlsRef.current;
    return () => {
      fileUrls.forEach((url) => URL.revokeObjectURL(url));
      fileUrls.clear();
    };
  }, []);

  // control popup whether there is uploaded files
  useConfirmNavigation();

  const { setHasContent, setPath } = usePost();

  useEffect(() => {
    setHasContent?.(true);

    return () => {
      setHasContent?.(false);
      setPath?.("");
    };
  }, []);

  useEffect(() => {
    if (!location?.state?.status) {
      navigate("/publish");
    }
  }, []);

  // make carousel controllable once clicking on carousel
  const carouselRef = useRef<CarouselRef | null>(null);

  return (
    <>
      <TitleHeader title="編輯貼文" />
      <div className="edit-post-main-container main-body-spacing">
        {showProgress && (
          <LinearProgressWithLabel
            value={progress}
            showProgress={showProgress}
          />
        )}
        <div className="edit-btn-group">
          <button
            className="white-btn"
            onClick={() => {
              const currentState =
                editState === "creating"
                  ? {
                      uploadedFiles: uploadedFiles,
                      thumbnail: thumbnail,
                      status: editState,
                      postType: postType,
                      content: {
                        title,
                        descr,
                        purpose,
                        privacy,
                        region,
                        houseType,
                        price,
                        layout,
                        propertyAge,
                      },
                    }
                  : {
                      uploadedFiles: uploadedFiles,
                      thumbnail: thumbnail,
                      imgFiles: imgFiles ? imgFiles : [], // current images of post in db
                      videoFile: videoFile,
                      coverImg: coverImg,
                      removedFiles: location?.state?.removedFiles
                        ? location?.state?.removedFiles
                        : [],
                      removedCover: removedCover,
                      status: location?.state?.status,
                      postType: postType,
                      content: {
                        title,
                        descr,
                        purpose,
                        privacy,
                        region,
                        houseType,
                        price,
                        layout,
                        propertyAge,
                      },
                      isReEdit: true,
                      postId: location?.state?.postId, // 把id回傳,之後回傳回來
                    };

              const toPath =
                editState === "creating" ? "/publish" : "/re-publish";
              navigate(toPath, {
                state: currentState,
              });
            }}
          >
            {editState === "creating" ? "上一步" : "編輯上傳"}
          </button>
          <button className="white-btn" onClick={handleCreatePost}>
            {editState === "creating" ? "發佈貼文" : "完成編輯"}
          </button>
        </div>
        <div className="post-edit-main">
          <div className="edit-left-sec">
            {showThumbnail && (
              <>
                <div className="upload-thumbnail-box">
                  <label
                    className={`post-thumbnail ${
                      thumbnail && "has-post-thumbnail"
                    }`}
                    htmlFor="thumbnail-99"
                  >
                    {thumbnail || coverImg ? (
                      <img
                        className="thumbnail-img"
                        src={
                          thumbnail ? URL.createObjectURL(thumbnail) : coverImg
                        }
                        alt="thmbnail-img"
                      />
                    ) : (
                      <div className="thumbnail-upload-icon">
                        <img
                          src={TemplateOtherImg.srcUpload_icon}
                          alt="upload-icon"
                        />
                        <div className="r-16">上傳封面</div>
                      </div>
                    )}
                    <input
                      type="file"
                      id="thumbnail-99"
                      accept="image/jpeg, image/png"
                      style={{ display: "none" }}
                      onChange={handleThumbnailUpload}
                    />
                  </label>
                </div>
                <div
                  className={`error-free-gap ${
                    thumbnailError !== "" && "thumbnail-error-gap"
                  }`}
                ></div>
                {thumbnailError !== "" && (
                  <div className="input-error r-14" style={{ color: "red" }}>
                    {thumbnailError}
                  </div>
                )}
              </>
            )}
            <div className="edit-input">
              <Input
                type="text"
                name="keyword"
                value={title}
                placeholder="標題"
                onChange={(titleInputValue) =>
                  handleTitleInput(titleInputValue)
                }
              />
              {titleError === "" && <div className="error-free-gap"></div>}
              {titleError !== "" && (
                <div className="input-error r-14" style={{ color: "red" }}>
                  {titleError}
                </div>
              )}
              <TextAreaWithHashtag
                ref={textareaRef}
                value={descr}
                placeholder="內容簡介"
                setHashtags={setHashtags}
                onChange={(textInputValue) => handleDescrInput(textInputValue)}
              />
            </div>
            {hashtags.length > 0 && (
              <div className="hashtag-recommendation r-14">
                {hashtags?.slice(0, 5)?.map((hashtag, i) => (
                  <div
                    key={`hashtag-${i}`}
                    className="suggest-keyword"
                    onClick={() => handleHashtag(hashtag)}
                  >
                    {hashtag}
                  </div>
                ))}
              </div>
            )}
            {descrError === "" && <div className="error-free-gap"></div>}
            {descrError !== "" && (
              <div className="input-error r-14" style={{ color: "red" }}>
                {descrError}
              </div>
            )}
            <div className="sell-or-lease">
              <button
                className={`primary-btn ${
                  purpose === "sell" ? "secondary-btn" : ""
                }`}
                onClick={() => {
                  setPurpose("sell");
                  setPrice("");
                }}
              >
                我要出售
              </button>
              <button
                className={`primary-btn ${
                  purpose === "rent" ? "secondary-btn" : ""
                }`}
                onClick={() => {
                  setPurpose("rent");
                  setPrice("");
                }}
              >
                我要出租
              </button>
            </div>
            <div className="filter-group">
              <FilterDropdown
                title="觀看權限"
                options={filterData.privacy}
                setOption={setPrivacy}
                optionSet={privacy}
              />
              {privacyError === "" && <div className="error-free-gap"></div>}
              {privacyError !== "" && (
                <div className="input-error r-14" style={{ color: "red" }}>
                  {privacyError}
                </div>
              )}
              <div
                className={`advanced-btn r-16 ${
                  isShow === true ? "transparent" : ""
                }`}
                onClick={() => setIsShow(!isShow)}
              >
                {"進階搜尋設定"}
                <img
                  src={TemplateNavBarImg.srcArrow_down_icon}
                  alt="arrow-icon"
                />
              </div>
              <div
                className={`advanced-filter ${
                  isShow && "show-advanced-filter"
                }`}
              >
                <FilterDropdown
                  title="地區"
                  options={filterData.regions}
                  setOption={setRegion}
                  optionSet={region}
                />
                <FilterDropdown
                  title="類型"
                  options={filterData.houseType}
                  setOption={setHouseType}
                  optionSet={houseType}
                />
                <FilterDropdown
                  title="開價"
                  options={
                    purpose === "sell"
                      ? filterData.sellPrice
                      : filterData.rentPrice
                  }
                  setOption={setPrice}
                  optionSet={price}
                />
                <FilterDropdown
                  title="格局"
                  options={filterData.houseLayout}
                  setOption={setLayout}
                  optionSet={layout}
                />
                <FilterDropdown
                  title="屋齡"
                  options={filterData.houseAge}
                  setOption={setPropertyAge}
                  optionSet={propertyAge}
                />
              </div>
            </div>
          </div>
          <div
            className={`edit-right-sec ${
              (uploadedFiles?.length + imgFiles?.length === 1 || videoFile) &&
              "single-item"
            }`}
          >
            <Carousel
              interval={null}
              ref={carouselRef}
              tabIndex={0}
              style={{ outline: "none" }}
            >
              {uploadedFiles?.map((file: File, i: number) => {
                return (
                  <Carousel.Item key={`slide-${i}`}>
                    {file?.type.includes("video") ? (
                      <video
                        src={fileUrlsRef.current.get(file.name) || ""}
                        controls
                        controlsList="nodownload"
                        disablePictureInPicture
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded ${file.name}`}
                        draggable="false"
                      />
                    )}
                  </Carousel.Item>
                );
              })}
              {imgFiles?.map((file: string, i: number) => {
                return (
                  <Carousel.Item key={`slide-${i}`}>
                    <img src={file} alt={file} draggable="false" />
                  </Carousel.Item>
                );
              })}
              {videoFile && (
                <Carousel.Item>
                  <video
                    src={videoFile}
                    controls
                    controlsList="nodownload"
                    disablePictureInPicture
                  />
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>
      {notEditable && (
        <EditWarningPopup
          title={"無有效方案"}
          context={"目前無購買方案，無法新增公開貼文。"}
          setConfirmPopup={setNotEditable}
        />
      )}
      {hasErrorRes && (
        <EditWarningPopup
          title={"上傳失敗"}
          context={
            "因網路傳輸或訊號不穩造成錯誤，\r\n請於五分鐘後重新送出即可。\r\n點擊了解返回前一編輯頁面，\r\n內容會完整保留。"
          }
          setConfirmPopup={setHasErrorRes}
        />
      )}
    </>
  );
};

export default EditPostPage;
