//react
import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
//components
import MainNavBar from "./components/MainNavBar/MainNavBar";
import SystemAnnouncePopup from "./components/SystemAnnouncePopup/SystemAnnouncePopup";
import SystemPopup from "./components/SystemPopup/SystemPopup";
import CompulsoryLogoutPopup from "./components/CompulsoryLogoutPopup/CompulsoryLogoutPopup";
import ReportPopupModal from "./components/ReportPopupModel/ReportPopupModel";
import AuthPopupModal from "./components/AuthPopupModel/AuthPopupModel";
import ServicePolicyPopupModel from "./components/ServicePolicyPopupModel/ServicePolicyPopupModel";
import PrivacyPolicyPopupModel from "./components/PrivacyPolicyPopupModel/PrivacyPolicyPopupModel";
import NavigationGuard from "./components/NavigationGuard/NavigationGuard";
import MaintenancePopupModel from "./components/MaintenancePopupModel/MaintenancePopupModel";
//context
import { FilterProvider } from "./context/HomeFilterContext";
import { ChatProvider } from "./context/ChatProvider";
import { CommentProvider } from "./context/CommentProvider";
import { NotifiProvider } from "./context/NotifiProvider";
import { AuthProvider } from "./context/AuthContext";
import { PostProvider, usePost } from "./context/PostProvider";
import { useNotifi } from "./context/NotifiProvider";
import { useAuth } from "./context/AuthContext";
//api
import { getStatusOfMaintenance } from "./api/maintenance";
//types
import { FbAuthRes } from "./types/fbLogin";
//main routing
import { Main } from "./Routing/Routing";
//style
import "./styles/base.scss";
import "./styles/reset.scss";

const queryClient = new QueryClient();

// System notification
const SystemNotifyPopup = () => {
  const { systemShow, systemTitle, systemContent } = useNotifi();

  return systemShow ? (
    <SystemAnnouncePopup
      systemTitle={systemTitle}
      systemContent={systemContent}
    />
  ) : (
    <></>
  );
};

// notification - post can not be found
const PostErrorNotifyPopup = () => {
  const { postError } = useNotifi();

  return postError ? (
    <SystemAnnouncePopup
      systemTitle={`貼文已被移除`}
      systemContent={`此則貼文目前不存在`}
    />
  ) : (
    <></>
  );
};

// Popup - Post is being Processed
const PostProcessPopup = () => {
  const { postProcessing, setPostProcessing } = useNotifi();

  return postProcessing ? (
    <SystemPopup
      popupMsg="貼文處理中，完成將後會通知"
      popupIcon=""
      setShow={setPostProcessing}
    />
  ) : (
    <></>
  );
};

// Popup - report disturbing content
const ReportPopup = () => {
  const {
    reportShow,
    setReportShow,
    reportId,
    reportType,
    reportedId,
    content,
  } = usePost();

  return reportShow ? (
    <ReportPopupModal
      setReportPopup={setReportShow}
      ownerId={reportedId}
      postId={reportId}
      type={reportType}
      content={content}
    />
  ) : (
    <></>
  );
};

// Auth Popup
const AuthPopup = () => {
  const {
    showAuth,
    showPolicyOne,
    showPolicyTwo,
    setShowAuth,
    setShowPolicyOne,
    setShowPolicyTwo,
  } = useAuth();

  return showAuth ? (
    <>
      {showAuth && (
        <AuthPopupModal
          setShowLogin={setShowAuth}
          setShowPolicyOne={setShowPolicyOne}
          setShowPolicyTwo={setShowPolicyTwo}
        />
      )}
      {showPolicyOne && (
        <ServicePolicyPopupModel setShowPolicyOne={setShowPolicyOne} />
      )}
      {showPolicyTwo && (
        <PrivacyPolicyPopupModel setShowPolicyTwo={setShowPolicyTwo} />
      )}
    </>
  ) : (
    <></>
  );
};

// Popup- token is expired
const TokenExpiredPopup = () => {
  const { timeout, setTimeout } = useAuth();

  return timeout ? (
    <CompulsoryLogoutPopup
      setTimeout={setTimeout}
      title={"登入過期"}
      context={"登入時效已到期，重新登入"}
    />
  ) : (
    <></>
  );
};

// Popup - acoount is banned by system
const ViolationPopup = () => {
  const { isBanned, setIsBanned } = useAuth();

  return isBanned ? (
    <CompulsoryLogoutPopup
      setTimeout={setIsBanned}
      title={"帳號停用"}
      context={"您的帳號已被停用，請聯繫客服了解詳情 LINE帳號:@658oyspo"}
    />
  ) : (
    <></>
  );
};

// block screen - site is on maintenance
const MaintenanceBlockScreen = () => {
  const [isMaintained, setIsMaintained] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const getMaintainStatus = async () => {
      try {
        const status = await getStatusOfMaintenance();

        if (status?.isWebsiteOnMaintenance) {
          setIsMaintained(true);
          setMessage(status?.maintenanceMessage);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getMaintainStatus();
  }, []);

  return isMaintained ? <MaintenancePopupModel message={message} /> : <></>;
};

// Facebook SDK - start
interface FacebookSDK {
  init(params: {
    appId: string;
    autoLogAppEvents: boolean;
    xfbml: boolean;
    version: string;
  }): void; // Add more properties or methods as needed

  login: (func: (response: FbAuthRes) => void) => void;
}

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: FacebookSDK;
  }
}

class App extends Component {
  componentDidMount() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1142240723451522",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v19.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (!fjs) return;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
  // Facebook SDK - end

  render() {
    return (
      <React.StrictMode>
        <React.Fragment>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <FilterProvider>
                <ChatProvider>
                  <CommentProvider>
                    <NotifiProvider>
                      <PostProvider>
                        <AuthProvider>
                          <div className="App responsive-large-screen">
                            <Routes>
                              <Route
                                path="/*"
                                element={
                                  <>
                                    <MainNavBar />
                                    <Main />
                                    <SystemNotifyPopup />
                                    <PostProcessPopup />
                                    <TokenExpiredPopup />
                                    <ViolationPopup />
                                    <PostErrorNotifyPopup />
                                    <ReportPopup />
                                    <AuthPopup />
                                    <NavigationGuard />
                                    <MaintenanceBlockScreen />
                                  </>
                                }
                              />
                            </Routes>
                          </div>
                        </AuthProvider>
                      </PostProvider>
                    </NotifiProvider>
                  </CommentProvider>
                </ChatProvider>
              </FilterProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </React.Fragment>
      </React.StrictMode>
    );
  }
}

export default App;
