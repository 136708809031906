//react
import { useEffect, useState, useRef, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
//conmponent
import PostEditDropDown from "../PostEditDropDown/PostEditDropDown";
import CommentsBox from "../CommentsBox/CommentsBox";
//context
import { useFilter } from "../../context/HomeFilterContext";
import { useAuth } from "../../context/AuthContext";
//bootstrap
import Overlay from "react-bootstrap/Overlay";
//api
import { postIsFollowing, postFollowOrUnfollow } from "../../api/users";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//type
import { TypePostDetail } from "../../types/posts";
//style
import "./HouseDetailCard.scss";

interface HouseDetailCardProps {
  postId: string;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  postDetail: TypePostDetail;
  commentSecRef: React.RefObject<HTMLDivElement>;
}

const HouseDetailCard: React.FC<HouseDetailCardProps> = (props) => {
  const [isFollowing, setIsFollowing] = useState();
  const [fetchAgain, setFetchAgain] = useState(false);

  // context - memberId
  const { currentMember, isAuthenticated, setShowAuth } = useAuth();

  // Show dorpdown
  const [show, setShow] = useState(false);

  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  // handle hashtags

  const { setSelectedHashTag } = useFilter();

  const renderTextWithHashtags = (text: string) => {
    const words = text?.replaceAll("#", " #")?.split(" ");

    return words?.map((word, i) => {
      if (word?.startsWith("#")) {
        return (
          <span
            key={`context${i}`}
            style={{ color: "#0175FD", cursor: "pointer" }}
            onClick={() => {
              setSelectedHashTag?.(word);
              navigate(`/hashtag-posts/&${word}`);
              props?.setIsShow(false);
            }}
          >
            {word}
          </span>
        );
      } else {
        return <span key={`context${i}`}>{word}</span>;
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(target)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  const handleFollowOrUnFollow = async () => {
    try {
      const res = await postFollowOrUnfollow({
        FollowerId: currentMember?.currentMemberId, //自己
        FollowedId: props?.postDetail?.memberId, //要追蹤對象
      });

      if (res?.success) {
        setFetchAgain(!fetchAgain);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Check if current user is following the id of other user
  useEffect(() => {
    // make sure its not on current user's profile page
    if (props?.postDetail?.memberId !== currentMember?.currentMemberId) {
      const postIsFollowingAsync = async () => {
        try {
          const isFollowing = await postIsFollowing({
            FollowerId: currentMember?.currentMemberId, //自己
            FollowedId: props?.postDetail?.memberId, //要追蹤對象
          });

          setIsFollowing(isFollowing);
        } catch (error) {
          console.log(error);
        }
      };

      postIsFollowingAsync();
    }
  }, [fetchAgain, currentMember?.currentMemberId, props?.postDetail?.memberId]);

  return (
    <>
      <div className="house-detailcard-container">
        <div className="owner-sec">
          <div
            className="owner-profile"
            onClick={() => {
              if (
                props?.postDetail?.memberId !== currentMember?.currentMemberId
              ) {
                navigate(
                  `/userinfo/${props?.postDetail?.memberId?.substring(0, 16)}`,
                  {
                    state: { userId: props?.postDetail?.memberId },
                  }
                );
              } else {
                navigate(
                  `/myinfo/${currentMember?.currentMemberId?.substring(0, 16)}`
                );
              }
            }}
          >
            <img src={props?.postDetail?.memberAvatar} alt="owner-pic" />
            <span className="sb-16">{props?.postDetail?.memberName}</span>
          </div>
          {props?.postDetail?.memberId !== currentMember?.currentMemberId && (
            <button
              className={`follow-btn ${isFollowing && "is-following-btn"}`}
              onClick={() => {
                isAuthenticated
                  ? handleFollowOrUnFollow()
                  : setShowAuth?.(true);
              }}
            >
              <img src={TemplateCardImg.srcEmpty_star_icon} alt="" />
              <span className="sb-12">{isFollowing ? `取消關注` : `關注`}</span>
            </button>
          )}
        </div>
        <div className="property-detail-sec">
          {isAuthenticated && (
            <div
              ref={ref}
              className="dropdown-action"
              onClick={(e) => {
                setShow(!show);
                setTarget(e.target as HTMLElement);
              }}
            >
              <img src={TemplateCardImg.srcDots_menu_icon} alt="menu-icon" />
            </div>
          )}
          <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref}
          >
            <div>
              <PostEditDropDown
                postId={props?.postId}
                memberId={props?.postDetail?.memberId}
                postType={props?.postDetail?.series ? "video" : "image"}
                setIsShow={props.setIsShow}
                setShow={setShow}
              />
            </div>
          </Overlay>
          <div className="property-title m-24">{props?.postDetail?.title}</div>
          <div className="created-date r-14">
            {`${props?.postDetail?.creationTime
              ?.substring(0, 10)
              ?.replaceAll("-", "/")}`}
          </div>
          <div className="property-detail-content r-14">
            <div className="description">
              {renderTextWithHashtags(props?.postDetail?.content)}
            </div>
            <div className="detail-content-list">
              <ul>
                {props?.postDetail?.region &&
                  props?.postDetail?.region !== "null" && (
                    <li>地區： {props?.postDetail?.region}</li>
                  )}
                {props?.postDetail?.houseType &&
                  props?.postDetail?.houseType !== "null" && (
                    <li>類型： {props?.postDetail?.houseType}</li>
                  )}
                {props?.postDetail?.price &&
                  props?.postDetail?.price !== "null" && (
                    <li>開價： {props?.postDetail?.price}</li>
                  )}
                {props?.postDetail?.layout &&
                  props?.postDetail?.layout !== "null" && (
                    <li>格局： {props?.postDetail?.layout}</li>
                  )}
                {props?.postDetail?.propertyAge &&
                  props?.postDetail?.propertyAge !== "null" && (
                    <li>屋齡： {props?.postDetail?.propertyAge}</li>
                  )}
              </ul>
            </div>
          </div>
          <CommentsBox
            commentSecRef={props?.commentSecRef}
            postId={props?.postId}
          />
        </div>
      </div>
    </>
  );
};

export default HouseDetailCard;
