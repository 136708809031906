//react
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
//conponents
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import ChatListCard from "../../components/ChatListCard/ChatListCard";
import ChatRoomMessages from "../../components/ChatRoomMessages/ChatRoomMessages";
//api
import { getChatRoomList } from "../../api/chat";
//signalR
import { SignalRService } from "../../signalR/SignalRService";
//context
import { useChat } from "../../context/ChatProvider";
import { useNotifi } from "../../context/NotifiProvider";
import { useAuth } from "../../context/AuthContext";
//type
import { TypeChatRoom } from "../../types/chats";
import { TypeMessage } from "../../types/chats";
//img
import { TemplateOtherImg } from "../../assets/data/imagesData";
//style
import "./ChatListPage.scss";

const ChatListPage = () => {
  const [roomList, setRoomList] = useState<TypeChatRoom[]>([]);
  const [connection, setConnection] = useState<SignalRService | null>(null);
  const location = useLocation();

  //context
  const {
    setSelectedRoomId,
    selectedRoomId,
    fetchAgain,
    setFetchAgain,
    setReceiverId,
    setAllFiles,
    setChat,
    setMessages,
  } = useChat();

  // context - memberId
  const { currentMember } = useAuth();

  useEffect(() => {
    const getRoomListAsync = async () => {
      try {
        const list = await getChatRoomList(
          currentMember?.currentMemberId //current user id
        );
        setRoomList([...list]);
        //console.log("refetch chatroom list");
      } catch (error) {
        console.log(error);
      }
    };
    getRoomListAsync();
  }, [fetchAgain, currentMember?.currentMemberId]);

  // part to be deleted later on when connection issue solved - start

  const queryClient = useQueryClient();

  useEffect(() => {
    const refetch = () => {
      setFetchAgain?.(!fetchAgain);
      setMessages?.([]);
      queryClient.invalidateQueries("/messages");
    };

    // Set up an interval to call the function every 5 seconds
    const interval = setInterval(refetch, 5000);

    // Clean up the interval when the component is unmounted or dependencies change
    return () => clearInterval(interval);
  }, [fetchAgain]);

  useEffect(() => {
    const reconnect = async () => {
      if (!connection?.isConnected()) {
        console.log("connection is lost! reconnecting to signalR server...");

        // setFetchAgain?.(!fetchAgain);
        // queryClient.invalidateQueries("/messages");
        // setMessages?.([]);

        await connection?.receiveConnectionIds();

        await connection?.startConnection();

        await connection?.startConnectionWithId(currentMember?.currentMemberId); //current user id
      }
    };

    // Set up an interval to call the function every 5 seconds
    const interval = setInterval(reconnect, 5000);

    // Clean up the interval when the component is unmounted or dependencies change
    return () => clearInterval(interval);
  }, [connection, currentMember?.currentMemberId]);

  // part to be deleted later on when connection issue solved - end

  // Notification
  const {
    notifiUsed,
    chatroom,
    opponent,
    setNotifiUsed,
    setChatroom,
    setOpponent,
  } = useNotifi();

  useEffect(() => {
    if (notifiUsed) {
      setSelectedRoomId?.(chatroom);
      setReceiverId?.(opponent);
      setNotifiUsed?.(false);
    }

    return () => {
      setNotifiUsed?.(false);
      setChatroom?.(""); // 應該可以不需要
      setOpponent?.(""); // 應該可以不需要
    };
  }, [notifiUsed]);

  // Clean up data when component unmounts
  useEffect(() => {
    return () => {
      setSelectedRoomId?.(""); // Clean up selectedRoomId
      setReceiverId?.(""); // Clean up receiverId
      setChat?.(""); // Clean up input of replying
      setAllFiles?.([]); // Clean up uploaded files
    };
  }, []);

  useEffect(() => {
    // set SelectedRoomId if user enter by clicking message button on other user's profile page
    // if (selectedRoomId === "" && location?.state?.chatroomIds) {
    //   setSelectedRoomId?.(location?.state?.chatroomIds?.chatroomId);
    // }
    // if (receiverId === "" && location?.state?.chatroomIds) {
    //   setReceiverId?.(location?.state?.chatroomIds?.receiverId);
    // }

    if (location?.state?.chatroomIds) {
      setSelectedRoomId?.(location?.state?.chatroomIds?.chatroomId);
      setReceiverId?.(location?.state?.chatroomIds?.receiverId);
    }
  }, []);

  // Setup SignalR service
  useEffect(() => {
    const signalRService = new SignalRService(
      "https://api.highlight11.com/chathub"
    );
    setConnection(signalRService);

    // Clean up the connection when the component unmounts
    return () => {
      signalRService.stopConnection().catch(console.error);
    };
  }, []);

  // Start connection with ID
  useEffect(() => {
    if (connection) {
      const connectWithId = async () => {
        try {
          await connection.receiveConnectionIds();

          await connection.startConnection();

          await connection.startConnectionWithId(
            currentMember?.currentMemberId
          ); //current user id
        } catch (error) {
          console.log(error);
        }
      };

      connectWithId();
    }
  }, [connection, currentMember?.currentMemberId]);

  // Receive opponent's Message to update chatroom list before enter a room
  const getOpponentMessage = async () => {
    try {
      if (!selectedRoomId) {
        const newMessage = (await connection?.receiveMessage()) as TypeMessage;

        if (newMessage) {
          setFetchAgain?.(!fetchAgain);
        }
      }
    } catch (error) {
      console.error("[Error receiving message]: ", error);
    }
  };

  getOpponentMessage();

  // Receive message sent by current user to update chatroom list (in a senario where current user sends message in mobile version of app with same account)
  const getSelfMessage = async () => {
    try {
      if (!selectedRoomId) {
        const newMessage =
          (await connection?.receiveSelfMessage()) as TypeMessage;

        if (newMessage) {
          setFetchAgain?.(!fetchAgain);
        }
      }
    } catch (error) {
      console.error("[Error receiving message]: ", error);
    }
  };

  getSelfMessage();

  // refetch chatroom list when user deletes or recalls message
  const refetchChatRoomList = async () => {
    try {
      const toUpdate = await connection?.UpdateChatroomListOn();

      if (toUpdate) {
        setFetchAgain?.(!fetchAgain);
      }
    } catch (error) {
      console.error("[Error updating chatroom list]: ", error);
    }
  };

  refetchChatRoomList();

  return (
    <>
      <TitleHeader title="訊息列表" />
      <div className="chatlist-main-container main-body-spacing">
        <div className="chat-main-sec">
          <div className="shared-link-reminder r-14">
            * 提醒：站內文章請善用"分享功能"，其餘可透過網址進行外部連線
          </div>
          <div className="disconnection-reminder r-14">
            * 如閒置超過15分鐘，請手動刷新頁面，謝謝
          </div>
          <div className="chatlist-group">
            {roomList?.map(
              (room) =>
                room?.oppositeUserId !==
                  "fb7d07d7-2125-ee48-e161-3a12b8c34640" && (
                  <ChatListCard key={room?.chatroomId} room={room} />
                )
            )}
          </div>
          <div className="chatbox-sec">
            {selectedRoomId ? (
              <>
                <ChatRoomMessages connection={connection} />
              </>
            ) : (
              <div className="unselected-box">
                <div className="unselected-state">
                  <img
                    src={TemplateOtherImg.srcChatroom_icon}
                    alt="chatroom-icon"
                  />
                  <div className="sb-24">未選擇任何聊天室</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatListPage;
