// react
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { useNavigate } from "react-router-dom";
//type
import { TypePayload } from "../types/auth";
// extention
import { decodeToken } from "react-jwt";
// hooks
import useTokenCheck from "../hooks/useTokenCheck";
//api
import { getCheckUserStatus } from "../api/auth";

interface DefaultAuthType {
  isAuthenticated: boolean;
  currentMember: { currentMemberId: string };
  timeout: boolean;
  isBanned: boolean;
  navAvatar: boolean;
  showAuth: boolean;
  showPolicyOne: boolean;
  showPolicyTwo: boolean;
  setTimeout?: Dispatch<SetStateAction<boolean>>;
  setIsBanned?: Dispatch<SetStateAction<boolean>>;
  storeToken: ({ token }: { token: string }) => void;
  setNavAvatar?: Dispatch<SetStateAction<boolean>>;
  setShowAuth?: Dispatch<SetStateAction<boolean>>;
  setShowPolicyOne?: Dispatch<SetStateAction<boolean>>;
  setShowPolicyTwo?: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
}

const defaultAuthContext: DefaultAuthType = {
  isAuthenticated: false, // 使用者是否登入的判斷依據，預設為 false，若取得後端的有效憑證，則切換為 true
  currentMember: { currentMemberId: "" }, // 當前使用者相關資料，預設為 null，成功登入後就會有使用者資料
  timeout: false,
  isBanned: false,
  navAvatar: false,
  showAuth: false,
  showPolicyOne: false,
  showPolicyTwo: false,
  storeToken: () => {},
  logout: () => {}, // 登入方法
};

const AuthContext = createContext<DefaultAuthType>(defaultAuthContext);

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: ReactNode; // This is the type for children
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [payload, setPayload] = useState<TypePayload>();
  const [timeout, setTimeout] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const [navAvatar, setNavAvatar] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [showPolicyOne, setShowPolicyOne] = useState(false);
  const [showPolicyTwo, setShowPolicyTwo] = useState(false);

  const navigate = useNavigate();

  const deleteToken = (tokenName: string) => {
    document.cookie = `${tokenName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict`;
  };

  useTokenCheck(
    setIsAuthenticated,
    setPayload,
    setTimeout,
    deleteToken,
    setIsBanned
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        timeout,
        setTimeout,
        isBanned,
        setIsBanned,
        navAvatar,
        setNavAvatar,
        showAuth,
        setShowAuth,
        showPolicyOne,
        setShowPolicyOne,
        showPolicyTwo,
        setShowPolicyTwo,
        currentMember: payload
          ? {
              currentMemberId: payload?.CurrentMemberId,
            }
          : { currentMemberId: "" },
        // {
        //   currentMemberId: "66a1d484-a632-f2bb-e16c-3a12791dae9a",
        // },
        storeToken: ({ token }: { token: string }) => {
          const tempPayload: any = decodeToken(token);

          if (tempPayload) {
            // only allow none violated user to login
            const checkValidUserAsync = async () => {
              try {
                const valid = await getCheckUserStatus(
                  tempPayload?.CurrentMemberId
                );

                if (valid === false) {
                  setIsBanned(true);
                  setPayload({ CurrentMemberId: "" });
                  setIsAuthenticated(false);
                } else {
                  setPayload(tempPayload);
                  setIsAuthenticated(true);
                  document.cookie = `shortToken=${token}; ${tempPayload?.exp}; path=/; Secure; SameSite=Strict`;
                }
              } catch (error) {
                console.log(error);
              }
            };

            checkValidUserAsync();
          } else {
            setPayload({ CurrentMemberId: "" });
            setIsAuthenticated(false);
          }
        },
        logout: async () => {
          try {
            deleteToken("shortToken");
            setIsAuthenticated(false);
            setPayload({ CurrentMemberId: "" });
            navigate(`/`);
          } catch (error) {
            console.log(error);
          }
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
