//img - NavBar Component
import logo_nav from "../images/logo_nav.png";
import home_icon from "../images/home_icon.svg";
import popular_icon from "../images/popular_icon.svg";
import publish_icon from "../images/publish_icon.svg";
import message_icon from "../images/message_icon.svg";
import arrow_down_icon from "../images/arrow_down_icon.svg";
import about_us_icon from "../images/about_us_icon.svg";
//img - Card Components
import login_img from "../images/login_img.png";
import report_bg from "../images/report_bg.png";
import fb_round from "../images/fb_round.svg";
import line_logo from "../images/line_logo.svg";
import empty_heart_icon from "../images/empty_heart_icon.svg";
import empty_star_icon from "../images/empty_star_icon.svg";
import dots_menu_icon from "../images/dots_icon.svg";
import chat_icon from "../images/chat_icon.svg";
import arrow_left_icon from "../images/arrow_left_icon.svg";
import upload_pic_icon from "../images/upload_pic_icon.svg";
import upload_file_icon from "../images/upload_file_icon.svg";
import close_icon from "../images/close_icon.svg";
import plan_individual_icon from "../images/plan_individual_icon.svg";
import plan_entry_icon from "../images/plan_entry_icon.svg";
import plan_rookie_icon from "../images/plan_rookie_icon.svg";
import plan_gold_icon from "../images/plan_gold_icon.svg";
import edit_icon from "../images/edit_icon.svg";
import delete_icon from "../images/delete_icon.svg";
import report_icon from "../images/report_icon.svg";
import email_icon from "../images/email_icon.svg";
import unsend_icon from "../images/unsend_icon.svg";
import service_logo from "../images/service_logo.svg";
import deliver_icon from "../images/deliver_icon.svg";
import playhead_icon from "../images/playhead_icon.svg";
import scroll_down_icon from "../images/scroll_down_icon.svg";
import scroll_down_opacity_icon from "../images/scroll_down_opacity_icon.svg";
import membership_icon from "../images/membership_icon.svg";
//img - Other Components
import search_icon from "../images/search_icon.svg";
import filter_icon from "../images/filter_icon.svg";
import bell_icon from "../images/bell_icon.svg";
import upload_icon from "../images/upload_icon.svg";
import share_icon from "../images/share_icon.svg";
import edit_photo_icon from "../images/edit_photo_icon.svg";
import success_icon from "../images/success_icon.svg";
import fail_icon from "../images/fail_icon.svg";
import chatroom_icon from "../images/chatroom_icon.svg";

//***** NavBar Component *****//
export const TemplateNavBarImg = {
  srcLogo_nav: logo_nav,
  srcHome_icon: home_icon,
  srcPopular_icon: popular_icon,
  srcPublish_icon: publish_icon,
  srcMessage_icon: message_icon,
  srcArrow_down_icon: arrow_down_icon,
  srcAbout_us_icon: about_us_icon,
  srcMembership_icon: membership_icon,
};

//***** Card Components *****//
export const TemplateCardImg = {
  srcLogin_img: login_img,
  srcReport_bg: report_bg,
  srcFb_round: fb_round,
  srcLine_logo: line_logo,
  srcEmpty_heart_icon: empty_heart_icon,
  srcDots_menu_icon: dots_menu_icon,
  srcEmpty_star_icon: empty_star_icon,
  srcChat_icon: chat_icon,
  srcShare_icon: share_icon,
  srcArrow_left_icon: arrow_left_icon,
  srcUpload_pic_icon: upload_pic_icon,
  srcUpload_file_icon: upload_file_icon,
  srcClose_icon: close_icon,
  srcPlan_individual_icon: plan_individual_icon,
  srcPlan_entry_icon: plan_entry_icon,
  srcPlan_rookie_icon: plan_rookie_icon,
  srcPlan_gold_icon: plan_gold_icon,
  srcEdit_icon: edit_icon,
  srcDelete_icon: delete_icon,
  srcReport_icon: report_icon,
  srcEmail_icon: email_icon,
  srcUnsend_icon: unsend_icon,
  srcService_logo: service_logo,
  srcDeliver_icon: deliver_icon,
  srcPlayhead_icon: playhead_icon,
  srcScroll_down_icon: scroll_down_icon,
  srcScroll_down_opacity_icon: scroll_down_opacity_icon,
};

//***** Other Components *****//
export const TemplateOtherImg = {
  srcSearch_icon: search_icon,
  srcFilter_icon: filter_icon,
  srcBell_icon: bell_icon,
  srcUpload_icon: upload_icon,
  srcEdit_photo_icon: edit_photo_icon,
  srcSuccess_icon: success_icon,
  srcFail_icon: fail_icon,
  srcChatroom_icon: chatroom_icon,
};
